import React, { useEffect, useState } from "react";
import { MdModeEditOutline, MdOutlineAdd, MdRestoreFromTrash } from "react-icons/md";
import { IoMdEye, IoMdTrash } from "react-icons/io";
import Header from "../../../components/header/Header";
import {
  Alert,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ItensServices from "../ItensServices";
import { FaSearch, FaTrash } from "react-icons/fa";
import AutoCompleteCustom from "../../../components/AutoCompleteCustom";
import LoadingData from "../../../components/LoadingData";
import { useDebounce } from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { FORM_EDIT_ITENS, FORM_ITENS } from "../../../routes/HashRouter";
import IconButtonCustom from "../../../components/IconButtonCustom";
import ModalConfirmCustom from "../../../components/ModalConfirmCustom";
import { alteraItem, listaHabilidades, listaItems } from "../reducer";
import ItemDetalhes from "./ItemDetalhes";
import { TIPOS } from "../../../config/constants";

const Itens = () => {
  const [descricao, setDescricao] = useState("");
  const [ensino, setEnsino] = useState();
  const [habilidade, setHabilidade] = useState();
  const [visibilidade, setVisibilidade] = useState(1);
  const [itemExcluir, setItemExcluir] = useState();
  const [loadingExcluir, setLoadingExcluir] = useState(false);

  const [modalDetalhes, setModalDetalhes] = useState();

  const { setValueDebounce } = useDebounce((text) => (text.length > 3 || text.length === 0) && load(1, text, false, ensino?.id, habilidade?.id, visibilidade));
  const { ensinos, itens, paginaAtual, listaItens, habilidades } = useSelector((state) => state.itens);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.global);

  useEffect(() => {
    load(1, descricao, false, ensino?.id, habilidade?.id, visibilidade);
  }, []);

  useEffect(() => {
    if (!ensinos) {
      ItensServices.getEnsinos(dispatch, { limit: 0 });
    }
  }, [ensinos]);

  const load = (page, descSearch, limpaLista = true, ensino_id, habilidade_id, visibilidade) => {
    let params = {
      page: page,
      descricao: descSearch,
      limpaLista: limpaLista,
      ensino_id,
      habilidade_id,
      visibilidade,
    };
    ItensServices.getItens(dispatch, params);
  };

  const excluir = () => {
    const callback = () => {
      setLoadingExcluir(false);
      setItemExcluir(undefined);
      load(1, descricao, false, ensino?.id, habilidade?.id, visibilidade);
    };

    const callbackError = () => {
      setLoadingExcluir(false);
    };

    setLoadingExcluir(true);

    ItensServices.excluirItem(dispatch, { id: itemExcluir.id }, callback, callbackError);
  };

  const renderButton = () => {
    return (
      <Button onClick={() => {navigate(FORM_ITENS);  dispatch(listaItems(null))}} endIcon={<MdOutlineAdd />} variant="contained">
        Cadastrar novo item
      </Button>
    );
  };

  const changeDescricao = (value) => {
    setDescricao(value);
    setValueDebounce(value);
  };

  const changeEnsino = (value) => {
    dispatch(listaHabilidades());
    setHabilidade();
    if (value) {
      ItensServices.getHabilidades(dispatch, { ensino_id: value.id, limit: 0, possui_itens: 1 });
    }
    load(1, descricao, false, value?.id, null, visibilidade);
    setEnsino(value);
  };

  const changeHabilidade = (value) => {
    setHabilidade(value ?? "");
    load(1, descricao, false, ensino?.id, value?.id, visibilidade);
  };

  const changeVisibilidade = (value) => {
    setVisibilidade(value);
    load(1, descricao, false, ensino?.id, habilidade?.id, value);
  };

  const CardItem = ({ item }) => {
    return (
      <Grid item md={4} xs={12} sm={6}>
        <Card
          style={{
            padding: 8,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div style={{ width: "100%", marginBottom: 10 }}>
              <span className="badge-item-card">
                {item.ensino.nome} | {item.habilidade.campo_experiencia.nome}
              </span>
            </div>
            {!!item.descricao && (
              <div className="div-card-item">
                <div className="title-card-item" dangerouslySetInnerHTML={{ __html: item.descricao }} />
              </div>
            )}
            {item.img && (
              <div style={{ height: 80, width: "100%" }}>
                <img src={item.img} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
              </div>
            )}
            <div className="div-card-item">
              <span className="subTitle-card-item">
                {item.habilidade.codigo} : {item.habilidade.descricao}
              </span>
            </div>
            <div className="div-card-item">
              <span className="subTitle-card-item" style={{ fontWeight: "800" }}>
                Criado por: <span style={{ fontWeight: "400" }}>{item.professor.nome}</span>
              </span>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 15 }}>
            <span style={{ fontSize: 12, fontWeight: "bold", color: item.visibilidade === 1 ? "#004f80" : "green" }}>
              {item.visibilidade === 1 ? "PRIVADO" : "PÚBLICO"}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButtonCustom
                style={{ marginRight: 1 }}
                tooltip={"Visualizar"}
                color={"#363a3c"}
                action={() => setModalDetalhes(item.id)}
              >
                <IoMdEye size={19} />
              </IconButtonCustom>
              {user?.relacionado?.id === item.professor_id && (
                <IconButtonCustom
                  style={{ marginRight: 1 }}
                  tooltip={"Editar"}
                  color={"#19a1bd"}
                  action={() => navigate(FORM_EDIT_ITENS.replace(":id", item.id))}
                >
                  <MdModeEditOutline size={19} />
                </IconButtonCustom>
              )}
              {user?.relacionado?.id === item.professor_id && (
                <IconButtonCustom tooltip={"Excluir"} color={"red"} action={() => setItemExcluir(item)}>
                  <IoMdTrash size={19} />
                </IconButtonCustom>
              )}
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Header title={"Banco de itens"} renderRight={renderButton()} />
      <Card style={{ padding: 8, marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div>Buscar por:</div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              id="textfield-filtrar-escola"
              label={"Buscar por descrição"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaSearch size={18} color={"#ccc"} />
                  </InputAdornment>
                ),
              }}
              value={descricao}
              onChange={(e) => changeDescricao(e.target.value)}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <AutoCompleteCustom
              label={"Selecione ensino"}
              placeholder={"Selecione ensino"}
              onChange={(value) => changeEnsino(value)}
              options={ensinos && ensinos?.dados ? ensinos?.dados : []}
              value={ensino ?? ""}
              empty={"Nenhum ensino encontrado"}
              loading={ensinos && ensinos.loading}
            />
          </Grid>
          <Tooltip title={!habilidades || (habilidades && habilidades?.loading)? "Para selecionar uma habilidade, selecione um ensino" : ""}>
            <Grid item md={4} sm={4} xs={12}>
                <AutoCompleteCustom
                  label={"Selecione uma habilidade"}
                  placeholder={"Selecione uma habilidade"}
                  onChange={(value) => changeHabilidade(value)}
                  options={habilidades && habilidades?.dados ? habilidades?.dados : []}
                  value={habilidade ?? ""}
                  empty={"Nenhum ensino encontrado"}
                  loading={habilidades && habilidades.loading}
                  disabled={!habilidades || (habilidades && habilidades?.loading)}
                />
            </Grid>
          </Tooltip>
          <Grid item md={4} sm={4} xs={12}>
            <FormControl style={{ width: "100%" }} size={"small"}>
              <InputLabel id="test-select-label">{"Visibilidade do item"}</InputLabel>
              <Select
                value={visibilidade ? visibilidade : ""}
                fullWidth
                onChange={(val) => changeVisibilidade(val.target.value)}
                label={"Visibilidade de item"}
                placeholder={"Visibilidade de item"}
                labelId="test-select-label"
              >
                {TIPOS.map((item, key) => (
                  <MenuItem key={key} value={item.id}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item md={12} xs={12}>
          {ensinos && <LoadingData msg={"Carregando ensinos..."} obj={ensinos} />}
          {itens && <LoadingData msg={"Carregando avaliações..."} obj={itens} />}
        </Grid>
        {itens && itens.dados && listaItens.length === 0 && (
          <Grid item md={12} xs={12}>
            <Alert color="info">Nenhum item encontrado</Alert>
          </Grid>
        )}
        {itens && itens.dados && (
          <Grid item xs={12} textAlign={"end"}>
            <span className="total-itens">
              Total: {itens.dados.total} {itens.dados.total > 1 ? "item(s)" : "item"}
            </span>
          </Grid>
        )}
        {listaItens.map((d, k) => (
          <CardItem item={d} key={k} />
        ))}
        {itens && itens.dados && itens.dados.last_page >= paginaAtual && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button onClick={() => load(paginaAtual, undefined, false)} variant="contained" size="small" color="info">
              Ver mais
            </Button>
          </Grid>
        )}
        <ModalConfirmCustom
          open={!!itemExcluir}
          title={"Deseja excluir este item?"}
          text="Ao excluir você não terá mais acesso ao mesmo"
          onConfirm={excluir}
          close={() => setItemExcluir(undefined)}
          loading={loadingExcluir}
        />
      </Grid>
      <ItemDetalhes id={modalDetalhes} onClose={() => setModalDetalhes(undefined)} />
    </>
  );
};

export default Itens;
